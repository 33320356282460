/*
 * Date: 2025
 * Description: Landing page when a user is validated !
 * Author: Philippe Leroux @ SKITSC
 *
 */


//Modules
import { useState , useEffect } from "react";
import { Typography , Paper , Grid2, Accordion , AccordionSummary , AccordionDetails , IconButton } from "@mui/material"
import formatDuration from 'format-duration';

//Components
import { CardLatest , CardLogin , CardData , CardCount } from "../components/utils/card"
import { CCount , CData , CLogin , CLatest } from "../components/utils/template.card"

//Theme
import { ThemeProvider } from '@mui/material/styles';
import { c_theme } from "../theme/custom.theme";

//Interfaces
import { i_card_count, i_card_data, i_card_latest, i_card_login, i_dash_data } from "../interface/card.interface";
import { i_inital_props } from "../interface/utility.interface";

//Utility functions
import { f_format_phone_cute , f_timestamp_to_date } from "../utility/utility";
import { Fetch } from "../components/api/fetch";

//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Dashboard = (props : i_inital_props) => {
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ Latest , setLatest ] = useState<i_card_latest>(CLatest)
    const [ Login , setLogin ] = useState<i_card_login>(CLogin)
    const [ Data , setData ] = useState<i_card_data>(CData)
    const [ Count , setCount ] = useState<i_card_count>(CCount)
    const [expanded, setExpanded] = useState<string | false>(false);
    

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        const f_fetch_cards = async() =>{
            setLoading(true)
            const output = await Fetch('/api/data/dashboard' , null , 'GET')
            if(output.type === 'Success'){
                const data : i_dash_data = output.data;
                var latest : i_card_latest = CLatest;
                var count : i_card_count = CCount;
                var login : i_card_login = CLogin;
                var carddata : i_card_data = CData
                if(data.latest_call.from !==  ''){
                    var latest_date = f_timestamp_to_date(data.latest_call.timestamp, 'long')
                    if(latest_date === undefined) latest_date = 0
                    latest = {
                        title : CLatest.title,
                        duration : formatDuration(Number(data.latest_call.duration) * 1000),
                        from : f_format_phone_cute(data.latest_call.from),
                        to : f_format_phone_cute(data.latest_call.to),
                        timestamp : latest_date
                    }
                }
                if(data.latest_login.email !== ''){
                    var date_login = f_timestamp_to_date(data.latest_login.date , 'long')
                    if(date_login === undefined) date_login = 0
                    login = {
                        title : CLogin.title,
                        date : date_login,
                        email : data.latest_login.email
                    }
                }
                if(data.month_call.month_call !== 0){
                    carddata = {
                        title : CData.title,
                        month_call : data.month_call.month_call,
                        week_call : data.month_call.week_call,
                        today_call : data.month_call.today_call
                    }
                }
                if(data.high_number.count !== 0){
                    count = {
                        title : CCount.title,
                        called : f_format_phone_cute(data.high_number.called),
                        caller : f_format_phone_cute(data.high_number.caller),
                        count : data.high_number.count,
                        called_count : data.high_number.called_count,
                        caller_count : data.high_number.caller_count
                    }
                }
                setLogin(login)
                setData(carddata)
                setCount(count)
                setLatest(latest)
            }else{
                props.logout()
            }
            setLoading(false)
        }
        f_fetch_cards();
    },[props])
    const initial_props = {
        loading : loading,
        setLoading : setLoading,
        CLatest : Latest,
        CLogin : Login,
        CData : Data,
        CCount : Count
    }
    const patchNotes = [
        { version: '0.1.12', date: '2025-02-26', changes: ['Fixed an issue with note insertion causing crash' , 'Now notes can be edited and deleted.' , 'Fixed an issue where when a user press enter on opening note modal will cause rerender of the app' , 'Added enter key functionnality for add and edit notes ( when input is focused an not empty, trigger the event', 'Added new endpoints for notes CRUD'],

        },           
        { version: '0.2.0', date : '2025-03-18', changes : ['Fixed an issue with missing records insertion' , 'Now the database is indexed by record_uuid instead of call_uuid since a call can have 2 records.' , 'Optimized backend code structure' , 'Adapted new logic for call record grouping']
        }
    ];
    return (
        <>
        <ThemeProvider theme={c_theme}>
            <Paper elevation={3} sx={{padding : '5em' , maxWidth : 1000 , display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , marginTop : '3vh'}}>
                <Typography variant="h4" align="center" sx={{marginBottom : '3vh'}}>Welcome to skitsc recall</Typography>
                <Grid2 justifyContent={"center"} container spacing={2}>
                    <Grid2 size={6}>
                        <CardLatest {...initial_props}></CardLatest>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardLogin {...initial_props}></CardLogin>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardData {...initial_props}></CardData>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardCount {...initial_props}></CardCount> 
                    </Grid2>
                </Grid2>
            </Paper>

            <Paper elevation={3} sx={{padding : '1em' , maxWidth : 1000 , display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , marginTop : '3vh'}}>
                {patchNotes.map((patch, index) => (
                        <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`} >
                                <Typography sx={{ fontWeight: 'bold' }}>Version {patch.version} - {patch.date}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {patch.changes.map((change, change_index) => (
                                    <Typography key={change_index}>- {change}</Typography>
                                ))}
                            </AccordionDetails>
                    </Accordion>
                ))}
            </Paper>
        </ThemeProvider>
        </>
    )
}
export default Dashboard