/*
 * Date: 2025
 * Description: Logout page
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { useEffect , useContext} from "react";

//API Call
import { logout } from "../components/api/fetch";

//Context
import { MainContext } from "../context/main.context";

//Themes
import { c_theme } from "../theme/custom.theme";
import { ThemeProvider } from '@mui/material/styles';

//Component
import Loader from "../components/utils/loader";

//Interfaces
import { i_inital_props } from "../interface/utility.interface";


const Logout = ( props : i_inital_props ) => {
    const { HandleLogout } = useContext(MainContext)
    useEffect(() => {
        const TriggerLogout = async() => {
            await logout();
            HandleLogout();
        }
        TriggerLogout();//eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return  (
        <ThemeProvider theme={c_theme}><Loader/></ThemeProvider>
    )
}

export default Logout;