/*
 *
 * Filename: filter.table.tsx
 * Date: 2023-05-03
 * Description: Custom grid for filters and search
 * Author: Philippe Leroux @SKITSC
 *
 */

//Modules
import { Fragment, useContext, useState} from 'react';
import { MainContext } from '../../context/main.context';
import { IconButton , Box , Table , TableBody , TableCell , TableContainer ,  TableRow , TableHead, Tooltip , Paper , CircularProgress , Typography , Badge } from "@mui/material";
import formatDuration from 'format-duration';

//Utils
import { f_timestamp_to_date , f_format_phone_cute , handlePhone } from '../../utility/utility';

//Interfaces && types
import { i_records_tbl_props, i_records, i_records_row } from '../../interface/records.interface';

//Icons
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { BorderTop, DeleteForeverRounded } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import NotesIcon from '@mui/icons-material/Notes';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InfoIcon from '@mui/icons-material/Info';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const FormatedIvr = ( row : i_records ) => {
    var title = "Ivr press : " + row.input
    return (
        <Tooltip sx={{ textDecoration : 'underline'}} title={title}>
            <Typography>{row.input}</Typography>
        </Tooltip>
    )
}

const TableSSR = ( props : i_records_tbl_props) => { 
    const { user } = useContext(MainContext)
    const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

const toggleExpand = (call_uuid: string) => {
    setExpandedRows(prev => ({
        ...prev,
        [call_uuid]: !prev[call_uuid]
    }));
};
    return (
        <Box sx={{height : 900}}>
            { props.loading ?
                        <Box component={'div'} > 
                            <CircularProgress size={100} color="inherit" sx={{  position: 'absolute',top:'50%' , left: '50%', transform: 'translate(-50%, -50%);', width : 100 , height : 100 , marginLeft: -5 }} />  
                        </Box>

                   :
            <TableContainer component={Paper}  sx={{ maxHeight : 900 }}>
                <Table sx={{ minWidth: 500 }} aria-label="records table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 20}} align='center'></TableCell>
                            <TableCell style={{ width : 50}} align='center'>IVR input</TableCell>
                            <TableCell style={{ width : 50}} align='center'>Play</TableCell>
                            <TableCell style={{ width : 180}} align='center'>Call Date</TableCell>
                            <TableCell style={{ width : 160}} align='center'>From</TableCell>
                            <TableCell style={{ width : 160}} align='center'>To</TableCell>
                            <TableCell style={{ width : 100}} align='center'>Duration</TableCell>
                            <TableCell style={{ width : 80}} align='center'>Notes</TableCell>
                            <TableCell style={{ width : 120}} align='center'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                <TableBody>
                {props.search.offset > 0 && props.data.map((item: i_records_row, index: number) => (
        <Fragment key={index}>
          
            {item.records.slice(0, expandedRows[item.call_uuid] ? item.records.length : 1).map((row: i_records, index: number) => (
                <TableRow key={index} sx={[props.target.record_uuid === row.record_uuid && props.isPlaying ? { backgroundColor: '#FFF9A6' } : { backgroundColor : expandedRows[item.call_uuid] ? '#BCEBD7' : 'initial' ,
                 border : expandedRows[item.call_uuid] ? '1px solid black' : 'initial' } , index === 0 && { borderBottom : 'initial'} , index === 1 && { borderTop : 'initial' }]}>
                     <TableCell align='center'>
                        {(item.records.length > 1 && index === 0 ) &&  (
                            <IconButton onClick={() => toggleExpand(item.call_uuid)}>
                                <Badge badgeContent={item.records.length} color={'primary'}>
                                {expandedRows[item.call_uuid] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Badge>
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell align='center' component="th" style={{ width: 50, fontSize: 12 }}>
                        {row.input !== 0 && row.input < 10 ? <FormatedIvr {...row} /> : ''}
                    </TableCell>
                    <TableCell component="th" style={{ width: 50 }}>
                        <Tooltip title={row.record_uuid}>
                            <IconButton onClick={() => props.handlePlay(row)}>
                                {props.target.record_uuid === row.record_uuid && props.isPlaying ? <StopIcon /> : <PlayArrowIcon />}
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell component="th" style={{ width: 100 }} align='center'>
                        {f_timestamp_to_date(row.add_time, 'Long')}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align='center'>
                        {handlePhone(row.from_number, props.endpoints)}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align='center'>
                        {f_format_phone_cute(row.to_number)}
                    </TableCell>
                    <TableCell style={{ width: 100 }} align='center'>
                        {formatDuration(row.recording_duration * 1000)}
                    </TableCell>
                    <TableCell style={{ width: 100 }} align='center'>
                        <IconButton onClick={() => props.handleEdit(row)}>
                            {row.notes.length > 0 ? <NotesIcon color='primary' /> : <PostAddIcon color="primary" />}
                        </IconButton>
                    </TableCell>
                    <TableCell style={{ width: 100 }} align='center'>
                        {user.type === 'Admin' && row.events?.length > 0 &&
                            <Tooltip title={'To be implemented trace the whole call event'}>
                                <IconButton>
                                    <InfoIcon sx={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>
                        }
                        <IconButton onClick={() => props.handleDownload(row)}>
                            <DownloadIcon sx={{ color: 'red' }} />
                        </IconButton>
                        {user.type === 'Admin' &&
                            <IconButton onClick={() => props.setDelete(row)}>
                                <DeleteForeverRounded />
                            </IconButton>
                        }
                    </TableCell>
                </TableRow>
            ))}
        </Fragment>
    ))}
                </TableBody>
            
                </Table>
            </TableContainer>
            }
        </Box>
    )
};

export default TableSSR;