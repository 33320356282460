/*
 * Date: 2024
 * Description: Records note form
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import { Fragment, ReactElement } from "react";
import { Box , Typography , TextField, Grid2 , InputAdornment , IconButton, CircularProgress, Tooltip , Button } from "@mui/material";

//Interfaces
import { i_note_form_props } from "../../interface/utility.interface";
import { i_record_notes } from "../../interface/records.interface";
import { f_timestamp_to_date } from "../../utility/utility";

//Icons
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const NoteForm = ( props : i_note_form_props ) : ReactElement => {
    const handleSelect = ( type : 'edit' | 'delete' | 'initial' , item : i_record_notes) => {
        props.setSelectedNote(item)
        props.setDisplay(type);
    }
    // console.log(props.display , props.target.notes , props.target)
    return(
        <Box>
            <Tooltip title={"Here we could have a more efficient way to display unicity of a call. What about giving a name to call like ? who or what?"}>
                <Typography variant={'h6'} component={'h6'} align="center" sx={{ marginBottom : '1vh'}}>{props.title}</Typography>
            </Tooltip>
            <Box sx={{ padding : '8px' , border : '2px solid black' , marginBottom : '1vh'}}>
                <Box sx={{ padding : '6px' , alignItems : 'center'}}>
                    <Typography sx={{ textAlign : 'center' , fontWeight : 700}}>{props.sub_title}</Typography>
                </Box>
                <Box mb={3} sx={{}}>
                    {props.target.notes.length > 0 ?
                        <>
                            {props.display === 'initial' && 
                                <Grid2 container p={2} sx={{ border : '1px solid #3333',  maxHeight : '400px' , overflowY : 'auto' , alignItems : 'center' }}>
                                    <Grid2 size={12}>                         <Typography sx={{ textAlign : "center"}}>Notes ({props.target.notes.length})</Typography></Grid2>
                                
                                    {props.target.notes.map(( item : i_record_notes , index : number ) => (
                                    <Fragment key={index} >
                                        <Grid2 size={8.5} p={1}>
                                            <Typography sx={{ fontSize : 18 , fontWeight : 700}}>{item.note}</Typography>
                                        </Grid2>
                                        <Grid2 size={2} p={1}>
                                            <Typography sx={{ marginLeft : 'auto' , textAlign : 'right' , fontWeight : '700'}}>
                                                {f_timestamp_to_date(item.timestamp , 'long')}
                                            </Typography>
                                            
                                        </Grid2>
                                        <Grid2 size={1.5} p={1}>
                                            <IconButton onClick={() => handleSelect('edit' , item)}>
                                                <EditIcon sx={{cursor: 'pointer' , color : 'crimson'}} />
                                            </IconButton>
                                            <IconButton onClick={() => handleSelect('delete', item)}>
                                                <DeleteForeverIcon sx={{cursor: 'pointer'}} />
                                            </IconButton>
                                        </Grid2>
                                        <Grid2 size={12} sx={{ borderBottom : '1px solid #3333'}}></Grid2>
                                    </Fragment>
                                    ))}
                                </Grid2>
                            }
                            {(props.display === 'edit' || props.display === 'delete') &&
                                <Grid2 container sx={{ marginTop : '1vh'}}>
                                    {props.display === 'edit' ? 
                                        <Grid2 size={12}>
                                            <Box>
                                                <TextField autoFocus label={'Update this note'} onKeyDown={(e) => { if (e.key === 'Enter' && props.selected_note.note.trim() !== '') { props.callback()} }} helperText={props.error} error={props.error !== '' ? true : false} fullWidth value={props.selected_note.note}
                                                onChange={( e ) => props.setSelectedNote({...props.selected_note, note : e.target.value }) }   ></TextField>
                                                <Typography sx={{fontWeight : '700' , padding : 1 , textAlign : 'center'}}>
                                                    {f_timestamp_to_date(props.selected_note.timestamp , 'long')}
                                                </Typography>
                                            </Box>
                                        </Grid2>
                                    :
                                        <Grid2 size={12}>
                                            <Typography sx={{ textAlign : 'center' , fontWeight : 800 , fontSize : 20}}>Are you sure to delete this note</Typography>
                                            <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                <Typography sx={{ textAlign : 'center' , padding : 1 , textDecoration : 'underline' }}>{props.selected_note.note}</Typography>
                                                <Typography sx={{fontWeight : '700' , padding : 1}}>
                                                    {f_timestamp_to_date(props.selected_note.timestamp , 'long')}
                                                </Typography>
                                            </Box>
                                            
                                        </Grid2>
                                    }
                                    <Grid2 size={12} sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh'}}>
                                        <Button type='button' variant="contained" color="secondary" sx={{ marginRight : '1vh'}} onClick={() => props.setDisplay('initial')}>Cancel</Button>
                                        <Button type='button' variant="contained" color="primary" onClick={() => props.callback()}>{props.display === 'delete' ? "Delete" : "Update" }</Button>
                                    </Grid2>
                                </Grid2>
                            }
                          
                        </>
                        : <Box>No notes yet.</Box>
                    }
                </Box>
            </Box>
            {props.display === 'initial' &&
                <TextField autoFocus label={'Add a new note :'}     
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && props.note.trim() !== '') {
                        props.callback();
                    }
                }}helperText={props.error} error={props.error !== '' ? true : false} fullWidth value={props.note} onChange={( e ) => props.setNote(e.target.value) }  InputProps={{
                    endAdornment: (
                    <InputAdornment position="end">
                        {props.loading ? 
                            <CircularProgress/>
                        :
                        <IconButton onClick={() => props.callback()}>
                            <AddIcon color={'primary'} />
                        </IconButton>
                        }
                    </InputAdornment>
                ),}}></TextField>
            }
        </Box>
    )
}

export default NoteForm;